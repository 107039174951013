<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    :filterFields="filterFields"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('AccountWorkScheduleCreate')"
    @edit="(id) => onEdit('AccountWorkScheduleEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'AccountWorkSchedules',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'AccountWorkSchedules',
      tableCaption: 'Рабочее время сотрудников',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Рабочее время сотрудников',
        },
      ],
      tableHeaders: [
        {
          text: 'Название аккаунта',
          alias: 'accountName',
          order: 'accountName',
        },
        {
          text: 'Дата и время начала работы',
          alias: 'beginWork',
          order: 'beginWork',
        },
        {
          text: 'Дата и время окончания работы',
          alias: 'endWork',
          order: 'endWork',
        },
        { alias: 'actions' },
      ],
      filterFields: [
        {
          text: 'Название аккаунта',
          alias: 'accountName',
        },
        {
          text: 'Дата и время начала работы',
          alias: 'beginWork',
          attributes: {
            type: 'datetime',
            filterType: 'DateTime',
            format: 'DD.MM.YYYY HH:mm',
            valueType: 'DD.MM.YYYY HH:mm',
            timeTitleFormat: 'DD.MM.YYYY HH:mm',
          },
        },
        {
          text: 'Дата и время окончания работы',
          alias: 'endWork',
          attributes: {
            type: 'datetime',
            filterType: 'DateTime',
            format: 'DD.MM.YYYY HH:mm',
            valueType: 'DD.MM.YYYY HH:mm',
            timeTitleFormat: 'DD.MM.YYYY HH:mm',
          },
        },
      ],
    };
  },
};
</script>
